::selection {
	color: #fff;
	background: #0663d2 !important;
}

.App {
	height: 100vh;
	width: 100vw;
	font-family: Rajdhani;
	display: flex;
	justify-content: flex-start;
	overflow-x: hidden;
	&::-webkit-scrollbar {
		display: none;
	}
}
