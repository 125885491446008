*,
body,
html {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	border: 0;
	word-wrap: break-word;
	text-decoration: none;
	-webkit-tap-highlight-color: transparent;
}
html {
	background: linear-gradient(
			89.87deg,
			rgba(0, 0, 0, 0.5) 7.43%,
			rgba(0, 0, 0, 0.1) 60%
		),
		url("/assets/png/background.png") no-repeat center center fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
body {
	position: relative;
	/* overflow: hidden; */
	// background: linear-gradient(
	// 		89.87deg,
	// 		rgba(0, 0, 0, 0.5) 7.43%,
	// 		rgba(0, 0, 0, 0) 44.81%
	// 	),
	// 	url("/assets/png/background.png");
	// background-size: cover;
	// background-repeat: no-repeat;
	// background-position: center center;
}
body main {
	width: 100vw;
	height: 100vh;
}
:not(body) {
	transition: all 0.5s cubic-bezier(0.22, 0.58, 0.12, 0.98);
}

@media only screen and (max-width: 780px) {
	html {
		position: relative;
		background: linear-gradient(
				171.87deg,
				rgba(0, 0, 0, 0.6) 55.43%,
				rgba(0, 0, 0, 0.15) 84.81%
			),
			url("/assets/png/background_phone2.png") !important;
		background-size: 100vw auto !important;
		background-repeat: no-repeat !important;
		// background-position: center center;
	}
}
